import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRating } from 'vuetify/lib/components/VRating';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VImg,{attrs:{"src":require("@/assets/images/pages/cafe-badilico.png")}}),_c(VCardText,{staticClass:"d-flex align-center"},[_c('div',[_c(VCardTitle,{staticClass:"ps-0"},[_vm._v(" Cafe Badilico ")]),_c(VCardSubtitle,{staticClass:"d-flex align-center flex-wrap ps-0 pb-0"},[_c(VRating,{attrs:{"dense":"","readonly":"","background-color":"warning","color":"warning","value":5}}),_c('span',{staticClass:"ms-3"},[_vm._v("5 Star (245)")])],1)],1),_c(VSpacer),_c(VBtn,{staticClass:"me-n3",attrs:{"icon":"","small":""}},[_c(VIcon,[_vm._v(" "+_vm._s(_vm.icons.mdiDotsVertical)+" ")])],1)],1),_c(VCardText,[_c('p',[_vm._v(" Italian Cafe ")]),_c('p',[_vm._v("The refrigerated dairy aisle of your local grocery store can be a great source tasty, convenient selections for your.")]),_c(VDivider),_c('p',{staticClass:"text-base text--primary mt-3"},[_vm._v(" Tonight’s availability ")]),_c('div',{staticClass:"demo-space-x"},[_c(VChip,[_vm._v(" 5:30PM ")]),_c(VChip,[_vm._v(" 7:00PM ")]),_c(VChip,[_vm._v("7:15PM")])],1)],1),_c(VCardActions,{staticClass:"dense"},[_c(VBtn,{attrs:{"text":"","color":"primary"}},[_vm._v(" Book Now ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }