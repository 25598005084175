import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,{staticClass:"align-start"},[_c('span',[_vm._v("Social Analytics")]),_c(VSpacer),_c(VBtn,{staticClass:"me-n3 mt-n2",attrs:{"icon":"","small":""}},[_c(VIcon,[_vm._v(" "+_vm._s(_vm.icons.mdiDotsVertical)+" ")])],1)],1),_c(VCardText,_vm._l((_vm.socialAnalytics),function(data,index){return _c('div',{key:data.avatar,class:("d-flex align-center " + (index > 0 ?'mt-4':''))},[_c(VAvatar,{class:("v-avatar-light-bg " + (data.color) + "--text"),attrs:{"color":data.color,"size":"40"}},[_c(VImg,{attrs:{"contain":"","height":"20","src":data.avatar}})],1),_c('div',{staticClass:"ms-4"},[_c('h2',{staticClass:"text-xl font-weight-semibold"},[_vm._v(" "+_vm._s(data.title)+" ")]),_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(data.subtitle)+" ")])])],1)}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }