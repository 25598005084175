import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,{staticClass:"align-start"},[_c('span',[_vm._v("Meeting Schedule")]),_c(VSpacer),_c(VBtn,{staticClass:"me-n3 mt-n2",attrs:{"small":"","icon":""}},[_c(VIcon,[_vm._v(" "+_vm._s(_vm.icons.mdiDotsVertical)+" ")])],1)],1),_c(VCardText,[_c(VList,{staticClass:"pt-0"},_vm._l((_vm.meetingSchedule),function(data,index){return _c(VListItem,{key:data.title,class:("d-flex px-0 " + (index > 0 ? 'mt-4':''))},[_c(VAvatar,{staticClass:"me-3",attrs:{"size":"38"}},[_c(VImg,{attrs:{"src":data.avatar}})],1),_c('div',{staticClass:"d-flex align-center flex-grow-1 flex-wrap text-no-wrap"},[_c('div',[_c('p',{staticClass:"font-weight-medium mb-0 text--primary"},[_vm._v(" "+_vm._s(data.title)+" ")]),_c(VIcon,{attrs:{"size":"14"}},[_vm._v(" "+_vm._s(_vm.icons.mdiCalendarBlankOutline)+" ")]),_c('span',{staticClass:"text-xs ms-1"},[_vm._v(_vm._s(data.dateAndTime))])],1),_c(VSpacer),_c(VChip,{class:("v-chip-light-bg " + (data.chipColor) + "--text font-weight-semibold mt-1"),attrs:{"small":"","color":data.chipColor}},[_vm._v(" "+_vm._s(data.chipText)+" ")])],1)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }