import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,{staticClass:"align-start"},[_c('span',[_vm._v("Team Members")]),_c(VSpacer),_c(VBtn,{staticClass:"me-n3 mt-n2",attrs:{"icon":"","small":""}},[_c(VIcon,[_vm._v(" "+_vm._s(_vm.icons.mdiDotsVertical)+" ")])],1)],1),_c(VSimpleTable,{staticClass:"team-table"},[_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v(" Name ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Project ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Task ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Progress ")])])]),_c('tbody',_vm._l((_vm.teamMembers),function(team){return _c('tr',{key:team.chipText},[_c('td',{staticClass:"d-flex"},[_c(VAvatar,{attrs:{"size":"38"}},[_c(VImg,{attrs:{"src":team.avatar}})],1),_c('div',{staticClass:"text-no-wrap ms-3"},[_c('p',{staticClass:"font-weight-medium mb-0 text--primary"},[_vm._v(" "+_vm._s(team.title)+" ")]),_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(team.subtitle))])])],1),_c('td',[_c(VChip,{class:("v-chip-light-bg " + (team.chipColor) + "--text font-weight-semibold"),attrs:{"small":"","color":"primary"}},[_vm._v(" "+_vm._s(team.chipText)+" ")])],1),_c('td',[_c('p',{staticClass:"font-weight-semibold mb-0"},[_c('span',{staticClass:"primary--text"},[_vm._v(_vm._s(team.task.split('/')[0]))]),_vm._v("/"),_c('span',{staticClass:"text--primary"},[_vm._v(_vm._s(team.task.split('/')[1]))])])]),_c('td',{staticClass:"d-flex align-center justify-center"},[_c('vue-apex-charts',{attrs:{"type":"radialBar","options":_vm.getChartConfig(team.chartColor),"series":team.series,"height":"90","width":"60"}})],1)])}),0)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }