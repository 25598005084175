import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,{staticClass:"align-start"},[_c('span',[_vm._v("Total Earning")]),_c(VSpacer),_c(VBtn,{staticClass:"me-n3 mt-n2",attrs:{"icon":"","small":""}},[_c(VIcon,[_vm._v(" "+_vm._s(_vm.icons.mdiDotsVertical)+" ")])],1)],1),_c(VCardText,[_c('div',{staticClass:"d-flex align-center"},[_c('h1',{staticClass:"text-4xl font-weight-semibold"},[_vm._v(" $24,895 ")]),_c('div',{staticClass:"d-flex align-center mb-n3"},[_c(VIcon,{attrs:{"size":"40","color":"success"}},[_vm._v(" "+_vm._s(_vm.icons.mdiMenuUp)+" ")]),_c('span',{staticClass:"text-base font-weight-medium success--text ms-n2"},[_vm._v("10%")])],1)]),_c('p',{staticClass:"mt-2 font-weight-medium text--primary mb-0"},[_vm._v(" Compared to $84,325 last year ")])]),_c(VCardText,_vm._l((_vm.totalEarning),function(earning,index){return _c('div',{key:earning.avatar,class:("d-flex " + (index >0 ? 'mt-6':''))},[_c(VAvatar,{staticClass:"me-3",attrs:{"rounded":"","size":"38","color":"#5e56690a"}},[_c(VImg,{attrs:{"contain":"","src":earning.avatar,"height":"20"}})],1),_c('div',{staticClass:"d-flex align-center flex-grow-1 flex-wrap"},[_c('div',{staticClass:"me-auto pe-2"},[_c('h4',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(earning.title)+" ")]),_c('span',{staticClass:"text-xs text-no-wrap"},[_vm._v(_vm._s(earning.subtitle))])]),_c('div',[_c('p',{staticClass:"text--primary font-weight-medium mb-1"},[_vm._v(" "+_vm._s(earning.earning)+" ")]),_c(VProgressLinear,{attrs:{"value":earning.progress,"color":earning.color}})],1)])],1)}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }