import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VImg } from 'vuetify/lib/components/VImg';
import { VTimeline } from 'vuetify/lib/components/VTimeline';
import { VTimelineItem } from 'vuetify/lib/components/VTimeline';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VImg,{attrs:{"src":require("@/assets/images/pages/activity-timeline.png")}}),_c(VCardTitle,[_vm._v("Activity Timeline")]),_c(VCardText,[_c(VTimeline,{staticClass:"activity-timeline timeline-custom-dense timeline-custom-dots",attrs:{"align-top":"","dense":""}},[_c(VTimelineItem,{attrs:{"color":"error","small":""}},[_c('div',{staticClass:"d-flex align-start justify-space-between flex-wrap"},[_c('h4',{staticClass:"font-weight-semibold me-1"},[_vm._v(" 8 Invoices have been paid ")]),_c('small',{staticClass:"text-no-wrap"},[_vm._v("Wednesday")])]),_c('span',[_vm._v("Invoices have been paid to the company.")]),_c('div',{staticClass:"d-flex align-center mt-1"},[_c(VImg,{attrs:{"contain":"","height":"26","max-width":"26","src":require("@/assets/images/icons/file-icons/pdf.png")}}),_c('h4',{staticClass:"font-weight-semibold ms-2"},[_vm._v(" invoice.pdf ")])],1)]),_c(VTimelineItem,{attrs:{"color":"primary","small":""}},[_c('div',{staticClass:"d-flex justify-space-between align-start flex-wrap"},[_c('h4',{staticClass:"font-weight-semibold me-1"},[_vm._v(" Create a new project for client 😎 ")]),_c('small',{staticClass:"text-no-wrap"},[_vm._v("April, 18")])]),_c('p',{staticClass:"mb-1"},[_vm._v(" Invoices have been paid to the company. ")]),_c('div',{staticClass:"d-flex align-center"},[_c(VAvatar,{attrs:{"size":"26"}},[_c(VImg,{attrs:{"src":require("@/assets/images/avatars/1.png")}})],1),_c('h4',{staticClass:"font-weight-semibold ms-3"},[_vm._v(" John Doe (Client) ")])],1)]),_c(VTimelineItem,{attrs:{"color":"info","small":""}},[_c('div',{staticClass:"d-flex align-start justify-space-between flex-wrap"},[_c('h4',{staticClass:"font-weight-semibold me-1"},[_vm._v(" Order #37745 from September ")]),_c('small',{staticClass:"text-no-wrap"},[_vm._v("January, 10")])]),_c('span',[_vm._v("Invoices have been paid to the company.")])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }