import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,{staticClass:"align-start"},[_c('span',[_vm._v("Website Statistics")]),_c(VSpacer),_c(VBtn,{staticClass:"me-n3 mt-n2",attrs:{"icon":"","small":""}},[_c(VIcon,[_vm._v(" "+_vm._s(_vm.icons.mdiDotsVertical)+" ")])],1)],1),_c(VCardText,{staticClass:"d-flex align-center justify-space-between pb-1 pt-5"},[_c('div',[_c('p',{staticClass:"text-5xl font-weight-semibold text--primary mb-2"},[_vm._v(" 4,590 ")]),_c('span',{staticClass:"text-base"},[_vm._v("Total Traffic")])]),_c('div',{staticClass:"chart-wrapper"},[_c('vue-apex-charts',{attrs:{"options":_vm.chartOptions,"series":_vm.series}})],1)]),_c(VCardText,{staticClass:"mt-2"},_vm._l((_vm.websiteStatistics),function(data,index){return _c('div',{key:data.title,class:("statistics-table d-flex align-center pt-3 " + (index > 0 ? 'mt-3':''))},[_c('span',{class:("badge-sm " + (data.color))}),_c('span',{staticClass:"ms-2"},[_vm._v(_vm._s(data.title))]),_c(VSpacer),_c('span',{staticClass:"font-weight-semibold text--primary me-6"},[_vm._v(_vm._s(data.traffic))]),_c('span',{staticClass:"font-weight-semibold text--primary me-1"},[_vm._v(_vm._s(data.percentage.slice(1)))]),_c(VIcon,{attrs:{"size":"25","color":data.percentage.charAt(0) === '+' ? 'success' :'error'}},[_vm._v(" "+_vm._s(data.percentage.charAt(0) === '+' ? _vm.icons.mdiChevronUp:_vm.icons.mdiChevronDown)+" ")])],1)}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }